.bottom_bar {
  z-index: 100;
  background-color: #142431;
  position: fixed;
  bottom: -1px;
  width: 100%;
  height: 55px;
  border-top: 1px solid rgba(48, 25, 124, 0.3);
}

.Active {
  border-top: 4px solid white;
  width: 55px;
}

.menu-Icon {
  width: 22px;
  height: 23px;
}

.menu-text-color {
  color: white;
  font-size: 14px;
  font-weight: 600;
  margin-top: 6px;
}

.menu-text {
  margin-left: 15px;
  color: black;
  font-size: 13px;
}

.element {
  margin-top: -0.5px;
  /* background: linear-gradient(-45deg, rgba(0,0,0,0.22), rgba(255,255,255,0.25)); */
  border-radius: 10%;
  justify-content: center;
  width: 49px;
  height: 49px;
  /* box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -3px -3px 6px 0 rgba(255, 255, 255, 0.3); */
}

.element-3 {
  margin-top: -0.5px;
  box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.25),
    inset -3px -3px 3px 0 rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  /* border:1px solid #31197C; */
  justify-content: center;
  width: 49px;
  height: 49px;
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1));
}

.todo-card {
  height: 40px;
  margin-bottom: 5px;
  background: #fffffb;
  border-radius: 7px;
  box-shadow: 2px 2px 6px #bebebe,
    -2px -2px 6px #ffffff;
}

.customers-card {
  height: 45px;
  margin-bottom: 5px;
  background: #fffffb;
  border-radius: 7px;
  border:none;
  box-shadow: 2px 2px 6px #bebebe,
    -2px -2px 6px #ffffff;
 /* box-shadow: 2px 2px 6px #bebebe,
    -2px -2px 6px #ffffff; */
} 

/* .carousel .carousel-indicators li {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: red;
  z-index: -100;
  position: relative;
  top: 2.7rem;
} */
/* .carousel-indicators li {
  width: 8px;
  height: 8px;
  bottom: 4rem;
  border-radius: 100%;
  background-color: black;
  width: 100%;
  position:absolute;
  z-index:100;
} */
/* .carousel-control-prev-icon, .carousel-control-next-icon {
  width: 8px;
  height: 8px;
  top: 4rem;
  border-radius: 100%;
  background-color: black;
  position:relative;
  z-index:100;
} */
/* .carousel-indicators button {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: black;
  z-index: 1;
  position: fixed;
  bottom:1rem;
}
.carousel-indicators button:not(:first-child) {
  margin-left: 1.3rem;
  z-index: 1;
  position: fixed;
  bottom: 1rem;
} */
.carousel-indicators button {
  width: 0.4rem !important;
  height: 0.4rem !important;
  border-radius: 50%;
  background-color: black;
  /* position: fixed;
  bottom: 5.5rem; */
}

.carousel-indicators .active {
  width: 0.4rem !important;
  height: 0.4rem !important;
  border-radius: 50%;
  background-color: gray;
  /* position: fixed;
  bottom: 5.5rem; */
}

.carousel-indicators button:not(:first-child) {
  margin-left: 0.3rem;
  /* position: fixed;
  bottom: 5.5rem; */
}

.slide {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  height: 10vh;
  gap: 4px;
}

.over-flow {
  width: 100%;
  height: 67vh;
  padding:0 3px;
  overflow-x: hidden;
  overflow-y: auto;
}

.over-flow-cust {
  width: 100%;
  height: 35vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.over-flow-emp {
  width: 100%;
  height: 69vh;
  padding:0 6px 0 4px;
  overflow-x: hidden;
  overflow-y: auto;
}
.over-flow-staff {
  width: 100%;
  height: 69vh;
  padding:0 10px;
  overflow-x: hidden;
  overflow-y: auto;
}
.over-flow-cust-rm {
  width: 100%;
  height: 55vh;
  padding:0 3px;
  overflow-x: hidden;
  overflow-y: auto;
}