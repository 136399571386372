body {
  font-family: Poppins;
}

.error {
  color: red;
  font-size: 9px;
  height: 0px;
  float: left;
}

.no-connection {
  position: absolute;
  top: 45%;
  left: 10%;
  right: 10%;
}
.color-white{
  color:white;
}
.fa-xs{
  font-size:12px;
}
/* (A) WRONG ORIENTATION - SHOW MESSAGE HIDE CONTENT */
@media only screen and (orientation:landscape) {
  #turn {
    display: block;
  }

  #container {
    display: none;
  }
}

/* (B) CORRECT ORIENTATION - SHOW CONTENT HIDE MESSAGE */
@media only screen and (orientation:portrait) {
  #turn {
    display: none;
  }

  #container {
    display: block;
  }
}